/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/consistent-type-definitions */
import { Component, computed, Input, signal, OnInit, input } from '@angular/core';
import { MatSidenavModule } from '@angular/material/sidenav'
import { MatIconModule } from '@angular/material/icon'
import { MatButtonModule } from '@angular/material/button'
import { MatListModule } from '@angular/material/list'
import { SharedModule } from '../shared.moudle';
import { ActivatedRoute, NavigationStart, Router, RouterModule } from '@angular/router';
import { AuthenticationService } from '../../../services/authentication.service';
import { StorageService } from '../../../services/storage.service';
import { USER_ID, USER_DISPLAY_NAME, USER_DP, ROLE } from '../../../data-models/user-data';
import { MemberAccessControl, AssessmentAccessControl, CalendarsAccessControl, ClassAccessControl, CourseAccessControl, DashboardAccessControl, LessonPlanAccessControl, ReportAccessControl } from '../../auth/access-control';
import { MenuItemComponent } from "./components/menu-item/menu-item.component";

export type MenuItem = {
  icon: string,
  label: string,
  route?: string,
  access?: any,
  access_label?: any,
  is_active?: any,
  subItems?: MenuItem[]
}

@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    SharedModule,
    MatButtonModule,
    RouterModule,
    MenuItemComponent
],
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss',
})
export class SidebarComponent implements OnInit {
  memberAccessControl = MemberAccessControl;
  assessmentAccessControl = AssessmentAccessControl;
  calendarsAccessControl = CalendarsAccessControl;
  classAccessControl = ClassAccessControl;
  courseAccessControl = CourseAccessControl;
  dashboardAccessControl = DashboardAccessControl;
  lessonPlanAccessControl = LessonPlanAccessControl;
  reportAccessControl = ReportAccessControl;

  sideNavCollapsed = signal(false)
  isMobileView = input(false)
  id: any;
  display_name: any;
  dp = signal('images/samples/ic_profile.png');
  fallbackUrl = 'images/samples/ic_profile.png';
  role: any;
  showNav = signal(true)
  @Input() set collapsed(val: boolean){
    this.sideNavCollapsed.set(val)
  }

  profilePicSize = computed(()=> this.sideNavCollapsed()? '32': this.isMobileView()?'32': '80')

  menuItems = signal<MenuItem[]>([
    {
      icon: "dashboard",
      label: "Dashboard",
      route: "dashboard",
      access: this.dashboardAccessControl.view.permissions,
    },
    {
      icon: "calendar_today",
      label: "Calendar",
      route: "calendar",
    },
    {
      icon: "image",
      label: "Gallery",
      route: `gallery`,
    },
    {
      icon: "person",
      label: "Members",
      route: "members",
      subItems:[
        {
          icon:'person',
          label: 'Members List',
          route: "",
        }
      ]
    }
  ])

  settingItems = signal<MenuItem[]>([
    // {
    //   icon: "",
    //   label: "Profile",
    //   route: "settings/profile",
    //   access: this.dashboardAccessControl.view.permissions,
    // },
    {
      icon: "settings",
      label: "General",
      route: "settings/general",
    },
    // {
    //   icon: "",
    //   label: "Appearance & Themes",
    //   route: `settings/themes`,
    // },
    // {
    //   icon: "",
    //   label: "Billings",
    //   route: `settings/billing`,
    // },
  ])

  constructor(
    private storage: StorageService,
    private router: Router,
    private auth: AuthenticationService,
    private route: ActivatedRoute,
  ) {
    router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        if(event['url'].includes('/settings')){
          this.showNav.set(false)
        } else {
          this.showNav.set(true);
        }
      }
    });
  }



  ngOnInit() {
    // const body = document.querySelector('body');
    this.id = this.storage.getJsonValue(USER_ID)
    this.menuItems.set([
      {
        icon: "dashboard",
        label: "Dashboard",
        route: "dashboard",
        access: this.dashboardAccessControl.view.permissions,
        access_label:'dashboard',
      },
      {
        icon: "calendar_today",
        label: "Calendar",
        route: "calendar",
        access: this.calendarsAccessControl.view.permissions,
        access_label: 'calendar'
      },
      // {
      //   icon: "image",
      //   label: "Gallery",
      //   route: `gallery/${this.id}`,
      // },
      {
        icon: "person",
        label: "Members",
        route: "members",
        access: this.memberAccessControl.view_member_management.permissions,
        access_label: 'member management',
        subItems:[
          {
            icon:'person',
            label: 'Members List',
            route: "list",
            access: this.memberAccessControl.view_member_listing.permissions,
            access_label: 'member management',
          },
          {
            icon:'verified_user',
            label: 'Pending Verifications',
            route:'pending',
            access: this.memberAccessControl.view_member_pending_invites.permissions,
            access_label: 'member management',
          },
          {
            icon:'account_balance',
            label: 'Fee Management',
            route:'fee_manage',
            access: this.memberAccessControl.view_fee_management.permissions,
            access_label: 'member management',
          },
          {
            icon:'loyalty',
            label: 'Membership Plans',
            route:'plans',
            access: this.memberAccessControl.view_membership_plans.permissions,
            access_label: 'member management',
          },
          {
            icon:'lock',
            label: 'User Role Management',
            route:'authorization',
            access: this.memberAccessControl.view_user_roles_management.permissions,
            access_label: 'member management',
          }
        ]
      },
      {
        icon: "group",
        label: "Groups",
        route: "groups",
        access: this.classAccessControl.view.permissions,
        access_label: 'groups management'
      },
      {
        icon: "add_box",
        label: "Recruitment",
        route: "recruitment",
        access: this.memberAccessControl.view_recruitment.permissions,
        access_label: 'member management'
      },
      {
        icon: "school",
        label: "Academic",
        route: "academics",
        access: this.courseAccessControl.view_academic_management.permissions,
        access_label: 'academic management',
        subItems:[
          {
            icon:'format_list_bulleted',
            label: 'Student List',
            route: "list",
            access: this.courseAccessControl.view_student.permissions,
            access_label: 'academic management',
          },
          {
            icon:'school',
            label: 'Courses List',
            route:'courses',
            access: this.courseAccessControl.view.permissions ,
            access_label: 'academic management',
          }
        ]
      },
      {
        icon: "sports",
        label: "Session Plan",
        route: "sessions",
        access: this.lessonPlanAccessControl.view_lesson_plan.permissions,
        access_label: 'lesson plan'
      },
      {
        icon: "menu_book",
        label: "Homeworks",
        route: "homeworks",
        access: this.lessonPlanAccessControl.view_lesson_plan.permissions,
        access_label: 'lesson plan'
      },
      {
        icon: "self_improvement",
        label: "Personnel Development",
        route: "personaldevs",
        access: this.lessonPlanAccessControl.view_lesson_plan.permissions,
        access_label: 'lesson plan'
      },
      {
        icon: "insert_chart",
        label: "Reports",
        route: "reports",
        access: this.reportAccessControl.view_student_list_reports.permissions,
        access_label: 'reports'
      },
      {
        icon: "directions_run",
        label: "Assessments",
        route: "assessments",
        access: this.assessmentAccessControl.view_assessment.permissions,
        access_label: 'assessments'
      },
    ])

    this.display_name = this.storage.getJsonValue(USER_DISPLAY_NAME)
    this.dp.set(this.storage.getJsonValue(USER_DP))

    if (this.auth.isDelegateLoggedIn()) {
      const permissions = this.storage.getJsonValue(ROLE) || [];
      this.role = permissions.role_name
    }
  }

  onImgError() {
    this.dp.set(this.fallbackUrl)
  }

  goback(){
    this.showNav.set(false)
    this.router.navigate([`/`]);
  }

}
