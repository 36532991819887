<div class="main-container" [ngClass]="[!showNav ? 'main-auth-screens ' : '']">
  <app-navbar #nav (collapsedChange)="onCollapsedChange($event)" class="nav" [ngClass]="[!showNav ? 'hide-screens' : '']"></app-navbar>
  <!-- <app-sidebar class="side-nav" [ngClass]="[!showNav ? 'hide-screens' : '']"></app-sidebar> -->
  <mat-sidenav-container [ngClass]="[!showNav ? 'hide-screens' : '']" [style.width]="sidenavWidth()">
    <mat-sidenav opened mode="side" [style.width]="sidenavWidth()">
      <app-sidebar [collapsed]="collapsed()" [isMobileView]="isMobile()"></app-sidebar>
    </mat-sidenav>
  </mat-sidenav-container>
  <div class="main text-base" [ngClass]="[!showNav ? 'login-screens' : '']" [@routeAnimations]="prepareRoute(outlet)">
      <app-skeleton-loader type="loader"></app-skeleton-loader>
      <div [ngClass]="[showNav? 'padder':'']">
        <router-outlet class="text-base" #outlet="outlet"></router-outlet>
      </div>
      <app-footer class="footer" [ngClass]="[!showNav ? 'hide-screens' : '']"/>
  </div>
</div>




